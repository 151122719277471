import axios from 'axios';
import store from 'store';
import {setFormData} from 'store/actions/questions';

export const FETCH_QUESTIONS = async (cb) => {
  const params = new URLSearchParams(document.location.search);
  const requestId = params.get('requestId');
  const token = store.getState().auth.token;
  await axios({
    url: `${process.env.REACT_APP_ORDER_SERVICE_API_HOST}/menu/mysteryQuestions?requestId=${requestId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      store.dispatch(setFormData(res.data));
    })
    .catch((err) => {
      const errorCode = err.response.data.errorCode;
      if (errorCode === 'OMR-402') {
        const amount = err.response.data.pointReward || 0;
        cb('/ms/results?type=s', {amount});
      } else if (errorCode === 'OMR-401') {
        cb('/ms/results?type=t');
      } else {
        cb('/ms/results?type=e', {retryLink: window.location.href});
      }
    });
};

export const SUBMIT_MS = async (requestId, comments, responses, cb) => {
  const token = store.getState().auth.token;
  const sampleObject = {...responses};
  delete sampleObject.message;
  const responseToBeSend = [];
  Object.keys(sampleObject).forEach((child) => {
    responseToBeSend.push({
      id: child,
      response: responses[child],
    });
  });
  const dataPayload = {
    requestId,
    comments,
    responses: responseToBeSend,
  };

  const {status} = await axios({
    url: `${process.env.REACT_APP_ORDER_SERVICE_API_HOST}/menu/submitReview`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: dataPayload,
  });
  if (status === 200) {
    const amount = store.getState().questions?.formData?.pointReward || 0;
    cb('/ms/results?type=s', {amount});
  } else if (status === 422) {
    cb('/ms/results?type=t');
  }
};

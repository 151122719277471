const initialState = {
    formData:{
        questions:[],
        expiredAt:null,
        menu:{}
    }
}

export default function auth(state = initialState, { type, payload }) {
    switch (type) {
        case 'SET_FORM_DATA':
            return {
                ...state,
                formData: payload,
            } 
        default:
            return state;
    }
}
const initialState = {
  token : null
}

export default function auth(state=initialState, {type, payload}) {
  switch(type) {
    case 'SET_AUTH_TOKEN':
      return {
        ...state,
        token : payload.token,
      }
    case 'REMOVE_AUTH_TOKEN':
      return initialState;      
    default:
      return state;
  }
}
const initialState = {
  show: false,
  type: 'warning',
  message: '',
}

export default function toast(state = initialState, {type, payload}) {
  switch (type) {
    case 'SET_TOAST_MESSAGE':
      return {
        ...state,
        show: true,
        toastType: payload.toastType,
        message: payload.message,
      }
    case 'REMOVE_TOAST':
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}
import React, {useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import Loadable from 'react-loadable';
import {useSelector} from 'react-redux';

//global components
import PulseLoader from './components/PulseLoader';

//global utils
import {useToken} from 'hooks';
import {LOCAL_STORAGE_AUTH_KEY} from 'util/constants';
import {FETCH_QUESTIONS} from 'util/fetch/MS';

import './App.scss';

const Loading = () => {
  return (
    <div className="loading-loadable">
      <PulseLoader />
    </div>
  );
};

//loadable components
const DashboardView = Loadable({
  loader: () => import('./views/Dashboard'),
  loading() {
    return <Loading />;
  },
});

const FormsView = Loadable({
  loader: () => import('./views/Forms'),
  loading() {
    return <Loading />;
  },
});

const ResultView = Loadable({
  loader: () => import('./views/Result'),
  loading() {
    return <Loading />;
  },
});

const App = () => {
  useToken();
  const token = useSelector(state => state.auth[LOCAL_STORAGE_AUTH_KEY]);

  useEffect(() => {
    token && !window.location.href.includes('results') && fetchInit();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const history = useHistory();

  const fetchInit = async () => await FETCH_QUESTIONS(history.push);

  return (
    <div className="container">
      <Switch>
        <Route exact path="/ms">
          <DashboardView />
        </Route>
        <Route exact path="/ms/fill-forms">
          <FormsView />
        </Route>
        <Route exact path="/ms/results">
          <ResultView />
        </Route>
      </Switch>
    </div>
  );
};

export default App;

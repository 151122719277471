import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function PulseLoader(props) {
  return (
    <div className={`loader ${props.className}`} style={props.style}>
      {[1, 2, 3].map((i) => (
        <div key={i} style={{width: props.size, height: props.size, backgroundColor: props.color}} />
      ))}
    </div>
  )
}

PulseLoader.defaultValue = {
  color: 'black',
  size: 12,
}

PulseLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

export default PulseLoader

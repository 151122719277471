import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import jwt from 'jsonwebtoken';
import {LOCAL_STORAGE_AUTH_KEY} from '../../src/util/constants';
import {setAuthToken, removeAuthToken} from '../store/actions/auth';

const invalidJWT = (token) => {
  if (!token) return true;

  const decoded = jwt.decode(token);
  const now = Math.trunc(new Date().getTime() / 1000);
  return !decoded || now > decoded.exp;
}

export const useToken = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const token = useSelector(state => state.auth[LOCAL_STORAGE_AUTH_KEY]);
  const globalWindowToken = window?.tokens?.order;
  const url = new URL(window.location.href);
  const urlQueryToken = url.searchParams.get('token');

  const removeTokenAndLogout = () => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
    dispatch(removeAuthToken());
    history.replace('/login');
  }

  const setToken = () => {
    const localStorageToken = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
    if (urlQueryToken) {
      if (invalidJWT(urlQueryToken)) return removeTokenAndLogout();

      localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, urlQueryToken);
      dispatch(setAuthToken({token: urlQueryToken, source: 'query'}));
      url.searchParams.delete('token');
      window.history.replaceState(null, '', url.href);
    }
    if (globalWindowToken) {
      if (invalidJWT(globalWindowToken)) return removeTokenAndLogout();

      localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, globalWindowToken);
      dispatch(setAuthToken({token: globalWindowToken, source: 'query'}));
    }
    else if (localStorageToken) {
      if (invalidJWT(localStorageToken)) return removeTokenAndLogout();

      !token && dispatch(setAuthToken({token: localStorageToken, source: 'localstorage'}));
    }
    else if (token && invalidJWT(token)) {
      removeTokenAndLogout();
    }
  }

  useEffect(() => {
    setToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return token;
};